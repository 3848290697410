/* eslint-disable jam3/no-sanitizer-with-danger */

import React from 'react'
import {Link} from 'react-router-dom'
import { withStyles } from '@material-ui/styles'
import styles from './View.Style'

import * as pages from 'common_constants/Pages'
import NavigationService from 'support/navigation/NavigationService'

const CaseStudyCard = (props) => {
    const { classes, title, subTitle, desc, imgsrc, cardNumber ,onclick,slug} = props

    const ROUTES = NavigationService.getRoutes()
    const detailCaseStudyPath = ROUTES[pages.DETAIL_CASESTUDY].path;

    return (
        <div className={classes.caseStudyBlock} >
            <div className={classes.caseStudyImgBlock}>
                <div className={classes.caseCardNumber}>
                    <p className={classes.caseContentNumber}> #{cardNumber} </p>
                </div>
                <img src={imgsrc} className={classes.img} alt='case studies' />
            </div>
            <div className={classes.caseStudyContentBlock}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, no-script-url */}
                {/* <a href='javascript:void(0)' role='button' onClick={onclick} > */}
                <Link to={`${detailCaseStudyPath}/${slug}`} >
                <h3 className={classes.caseStudyContentTitle}>{title}
                    <span>
                        <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.4133 5.04431L21.9427 16.1781L10.8088 27.7075" stroke="#7834FF" 
                            strokeWidth="1.5" 
                            strokeLinecap="round" 
                            strokeLinejoin="round" />
                        </svg>
                    </span>
                </h3>
                </Link>
                {/* </a> */}

                
                <h4 className={classes.caseStudyContentSubTitle}>{subTitle}</h4>
                <p className={classes.caseStudyContent}>  <div dangerouslySetInnerHTML={{ __html: desc }} /> </p>
            </div>
        </div>
    )
}


export default withStyles(styles)(CaseStudyCard)