import React, { Fragment, useEffect, useState } from 'react'
import * as PropTypes from 'prop-types'

const Image = props => {
  const { alt, className, defaultSrc, src, style } = props
  const [errored, setErrored] = useState(false)
  const [innerSrc, setInnerSrc] = useState(src)

  useEffect(() => {
    setInnerSrc(src)
    setErrored(false)
  }, [src])

  function onError() {
    if (!errored) {
      setErrored(true)
      setInnerSrc(defaultSrc)
    }
  }
  return (
      <img alt={alt} className={className} src={innerSrc || ''} onError={onError} style={style} />
 
  )
}

Image.defaultProps = {
  alt: '',
  src: '',
}

Image.propTypes = {
  alt: PropTypes.string,
  defaultSrc: PropTypes.string.isRequired,
  src: PropTypes.string,
}

export default Image
