
const styles = theme => ({
  
  navsec: {
    boxShadow: '0px 2px 3px #ddd',
    width: '100%',
    marginBottom: '3px',
    backgroundColor: theme.palette.secondary.main ? theme.palette.secondary.main : '#fff',
  },
  menuHumbergar: {
    width: '40px',
    display: 'block',
    backgroundColor: 'transparent',
    border: '0',
    margin: '0 16px 0 0',
    '& img': {
      width: '100%',
    },
    '@media (min-width:1025px)': {
      display: 'none',
    },
  },
  menuSystem: {
    display: 'flex',
    alignItems: 'center',
    '& .holder': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '4px',
      '& .menuItem': {
        height: '100%',
      },
    },

    '& .menuItem': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      color: 'currentColor',
      textDecoration:'none',
      '&:hover': {
        color: '#E60000',
        borderBottom: '4px solid #E60000',
        paddingBottom: '0',
        cursor: 'pointer',
        transition: '0.1s',

        '& img': {
          filter:
            'invert(10%) sepia(99%) saturate(7498%) hue-rotate(4deg) brightness(85%) contrast(121%)',
        },
      },
    },

  },

  navlist: {
    display: 'block',
    position: 'absolute',
    left: '0',
    top: '67px',
    backgroundColor: theme.palette.secondary.main ? theme.palette.secondary.main : '#fff',
    color:theme.palette.secondary.contrastText ? theme.palette.secondary.contrastText : '#333',
    width: '160px',
    height: '100%',
    zIndex: '111',
    padding: '30px',
 
    '& .holder': {
      display: 'block',
      justifyContent: 'left',
      alignItems: 'center',
      padding: '4px',
      '& .menuItem': {
        height: '100%',
      },
      '& .dropDown': {
        position: 'initail',
      },
    },

    '& .menuItem': {
      display: 'flex',
      justifyContent: 'left',
      alignItems: 'center',
      padding: '0px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      color: 'currentColor',
      textDecoration:'none',
      marginBottom:'8px',
      fontSize: '1.25rem',
      fontFamily: 'VodafoneRg-Regular',
    },


    '& .btn-transparent':{
      width:'100%',
      backgroundColor:'inherit',
      border:'0px',
      color:'currentColor',
      // padding:'8px',
      textAlign:'left',
      cursor:'pointer',
      textTransform:'uppercase',
      '&:hover':{
        color: '#E60000',
      },

      '& img':{
        width:'40px'
      }
    },

    '& .btn-transparent:disabled':{
      cursor:'auto',
      opacity:'0.5',
      pointerEvents:'none',
    }, 
    '& .menu-dropdown':{
      marginBottom:'8px'
    },
    '& .dropdown-menu':{
      position:'inherit',
      marginTop:'0px'
    }
    
  },

  menuSection: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '80px',
  },

  navContainer: {
    backgroundColor: '#FFFFFF',
    width: '100%',
    zIndex: '10',
    top: '0',
    height: '72px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontFamily: 'VodafoneRg-Regular',
  },
  rightNestedNavContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '28%',
    height: '100%',
    gridGap: '6px',
    '@media (max-width:767px)': {
      width: '100%',
    },
    '@media (max-width:1024px)': {
      width: '46%',
      justifyContent: 'end',
    },

    
  },
  alignlogo: {
    display: 'flex',
    justifyContent: 'space-between',
    padding:0,
    border:0,
  },
  leftNestedNavContainer: {
    fontSize: '24px',
    lineHeight:'1.6',
    display: 'flex',
    color: theme.palette.secondary.contrastText ? theme.palette.secondary.contrastText  : '#333',
    height: '100%',
    width: '66%',
    justifyContent: 'space-between',
    position: 'relative',
    margin: '0px 3rem ',
    alignItems:'center',
   
    '@media (max-width:1024px)': {
   
      width: '100%',
      display: 'none',
    },
    '@media (min-width:1025px) and  (max-width:1199px)': {
      fontSize: '20px',
    },

    '& .holder': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '4px',
      '& .menuItem': {
        height: '100%',
      },
    },

    '& .disableddropdown':{
      'pointer-events': 'none',
      opacity: '0.5'
    },

    '& .menuItem': {
      display: 'flex',
      // justifyContent: 'center',
      alignItems: 'center',
      // padding: '12px',
      // fontFamily:theme.fontFamily.fontFamilyRegular,
      fontSize:  theme.fontSize.fontsize18,
      fontFamily: 'VodafoneLt-Regular',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      color: 'currentColor',
      textDecoration:'none',position:'relative',
      '& :after':{
        display:'block',
        content: 'open-quote',
        width:'0%',
        height:'2px',
        background:'red'
      },
      '& :hover': {
        width:'100%',
        color: '#E60000',
        borderBottom: '1px solid #E60000',
        paddingBottom: '0',
        cursor: 'pointer',
        transition: '0.1s',

        '& img': {
          filter:
            'invert(10%) sepia(99%) saturate(7498%) hue-rotate(4deg) brightness(85%) contrast(121%)',
        },
      },
      '&.active': {
        borderBottom:'1px solid red',
      }
    },

    '& .menuItem:after':{
      content: 'no-open-quote',
      display:' block',
      width: '0%',
      height: '1px',
      background: 'red',
      position: 'absolute',
      bottom: 0,
      transition:'all 300ms',
     
    },
    '& .menuItem:hover:after':{
      width:'100%',
    },
    
    '& .btn-transparent':{
      width:'100%',
      backgroundColor:'inherit',
      border:'0px',
      fontSize:'1rem',
      color:'currentColor',
      // padding:'8px',
      textAlign:'left',
      cursor:'pointer',
      textTransform:'uppercase',
      '&:hover':{
        color: '#E60000',
      },

      '& img':{
        width:'40px'
      }
    },

    '& .btn-transparent:disabled':{
      cursor:'auto',
      opacity:'0.5',
      pointerEvents:'none',
    },
    

    '& .dropdown-menu':{
      backgroundColor: theme.palette.secondary.main,
      marginTop:'30px',
      '& li':{
        marginBottom:'8px',
      }
    }


  },
  
  chevronDown: {
    width: '14px',
    height: '14px',
    paddingTop: '4px',
    paddingLeft: '5px',
  },
  novaLogo: {
    height: '30px',
    alignSelf: 'center',
    cursor: 'pointer',
  },
  postIdea: {
    height: '44px',
    alignSelf: 'center',
    borderRadius: '44px',
    backgroundColor:theme.palette.primary.main,
    // backgroundColor: '#F63F40',
    color: theme.palette.primary.contrastText ? theme.palette.secondary.contrastText : '#333',
    boxShadow:'0 1px 2px #000',
    width: '161px',
    border: 'none',
    cursor: 'pointer',
    fontFamily: 'VodafoneRg-Regular',
    fontSize: '18px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    margin: 0,
    '@media (max-width:767px)': {
      // fontSize: "15px",
      display: 'none',
    },
  },
  searchIcon: {
    alignSelf: 'center',
    cursor: 'pointer',
    height: '32px',
    padding: '15px 30px',
    '@media (max-width:1024px)': {
      padding: '16px',
    },
  },
  profilePicHolder: {
    position: 'relative',
    display: 'flex',
  },
  profilePic: {
    alignSelf: 'center',
    height: '60px',
    width: '60px',
    borderRadius: '50%',
    border: '1.5px solid #fff',
    boxShadow: '0px 2px 3px #33333399',
  },
  notifications: {
    zIndex: 5,
    position: 'absolute',
    right: 30,
    top: '65%',
    backgroundColor: '#F63F40',
    color: '#FFFFFF',
    fontFamily: 'VodafoneRg-Regular',
    padding: '1px 6px 1px 6px',
    borderRadius: '25px',
    fontSize: '17px',
    cursor: 'pointer',
  },
  badge: {
    borderRadius: '20px',
    lineHeight: '20px',
    backgroundColor: '#F63F40',
    color: '#FFFFFF',
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    right: -3,
    top: '65%',
    padding: '1px',
    cursor: 'pointer',
  },
  burger: {
    width: '20px',
    height: '20px',
    lineHeight: '20px',
    fontSize: '17px',
  },
  navList: {
    fontFamily: 'VodafoneRg-Regular',
    position: 'relative',
    left: 9,
    bottom: -8,
  },
  dropDown: {
    position: 'absolute',
    top: '0',
    marginTop: '72px',
    width: '193px',
    backgroundColor: theme.palette.secondary.main,
    color:theme.palette.secondary.contrastText,
    zIndex: '1111',
    '@media (max-width:1024px)': {
      position: 'initial',
      marginTop: '0px',
      width: '100%',
    },
    '& div': {
      margin: '0',
      padding: '0.5em',
      fontSize: '24px',
      fontFamily: 'VodafoneLt-Regular',
      '@media (max-width:1024px)': {
        fontSize: '16px',
      },
      '&:hover': {
        color: '#E60000',
        cursor: 'pointer',
      },
    },
  },
  ideasDrop: {
    marginLeft: '6.2%',
  },
  challengeDrop: {
    marginLeft: '3.2%',
  },
  colorIcon:{
    color:theme.palette.secondary.contrastText
  },
  dropdownToggle :{
    display: 'flex',
    background: 'transparent',
    border: 0,
    padding: 0,
    margin: 0,
    alignItems: 'center',
    fontSize: '1rem',
    color: 'currentColor'
  }

})

export default styles
